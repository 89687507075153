import React from 'react';
import {
  navigate
} from 'gatsby';

import {
  getUrl,
  locales
} from '../utils/links';

const NotFoundPage = ({
  location
}) => {
  const {
    pathname
  } = location;
  const firstPart = pathname.split('/').filter(c => c).shift();
  const locale = locales.find(l => l === firstPart);
  const url = getUrl('/', locale);

  React.useEffect(() => {
    navigate(url);
  }, [url]);
  return null;
}

export default NotFoundPage;